import React from "react";

import { IComponentProps } from "../../models/types/";
import { combineClassNames } from "../../utils";

export const LogoIcon: React.FC<IComponentProps> = ({ id, className }) => {
	return (
		<svg className={combineClassNames("logo", className)} width="32" height="32" viewBox="0 0 32 32">
			<path
				d="M16.0001 0C24.8402 0 32 7.15977 32 15.9994C32 24.8397 24.8402 31.9995 16.0001 31.9995C7.15977 31.9995 0 24.8397 0 15.9994C0 7.15977 7.15977 0 16.0001 0Z"
				fill="#84DBFF"
			/>
			<path
				d="M31.9301 14.4339C31.9765 14.9638 32 15.4702 32 15.9994C32 24.794 24.9088 31.9302 16.1147 31.9995L6.99872 23.942C6.99872 23.5741 7.22869 23.4128 7.22869 23.0671C7.22869 19.1076 7.22869 15.1481 7.22869 11.188C7.22869 10.5901 7.22869 9.96806 7.22869 9.36955C7.22869 9.00108 7.22869 8.63253 7.22869 8.28754C7.59689 8.28754 7.94215 8.28754 8.31104 8.28754C13.6057 8.28754 18.8767 8.28754 24.1724 8.28754L25.0238 8.08105C25.3002 8.60967 31.8836 14.4339 31.9301 14.4339Z"
				fill="#77C5E6"
			/>
			<path d="M23.9418 10.9807H8.08075V22.8599H23.9418V10.9807Z" fill="white" />
			<path
				d="M8.08073 11.7058C8.08073 11.3054 8.40532 10.9808 8.80573 10.9808H23.2168C23.6172 10.9808 23.9418 11.3054 23.9418 11.7058V22.1349C23.9418 22.5353 23.6172 22.8599 23.2168 22.8599H8.80573C8.40532 22.8599 8.08073 22.5353 8.08073 22.1349V11.7058ZM25.0238 22.8599C25.0238 23.4575 24.5394 23.942 23.9418 23.942H8.08073C7.48315 23.942 6.99872 23.4575 6.99872 22.8599V10.9808V9.16244C6.99872 8.56521 7.48349 8.08105 8.08073 8.08105H23.9418C24.539 8.08105 25.0238 8.56521 25.0238 9.16244V10.9808V22.8599Z"
				fill="#4B4B55"
			/>
			<path
				d="M20.5122 9.50734C20.5122 9.89937 20.8343 10.2444 21.2257 10.2444C21.6399 10.2444 21.9621 9.89937 21.9621 9.50734C21.9621 9.11662 21.6399 8.79449 21.2257 8.79449C20.8343 8.79456 20.5122 9.11662 20.5122 9.50734Z"
				fill="#90DFAA"
			/>
			<path
				d="M22.4919 9.50734C22.4919 9.89937 22.814 10.2444 23.2054 10.2444C23.6197 10.2444 23.9418 9.89937 23.9418 9.50734C23.9418 9.11662 23.6197 8.79449 23.2054 8.79449C22.814 8.79456 22.4919 9.11662 22.4919 9.50734Z"
				fill="#FFD05B"
			/>
			<path
				d="M18.5312 9.50734C18.5312 9.89937 18.8546 10.2444 19.246 10.2444C19.6373 10.2444 19.9594 9.89937 19.9594 9.50734C19.9594 9.11662 19.6373 8.79449 19.246 8.79449C18.8545 8.79456 18.5312 9.11662 18.5312 9.50734Z"
				fill="#FD8469"
			/>
			<path
				d="M18.3482 18.624H13.6518V14.4803C13.6518 13.1683 14.7103 12.1085 16 12.1085C17.3119 12.1085 18.3481 13.1682 18.3481 14.4803V18.624H18.3482ZM14.7567 17.542H17.2655V14.4802C17.2655 13.7661 16.6905 13.214 16 13.214C15.3094 13.214 14.7567 13.7661 14.7567 14.4802V17.542Z"
				fill="#FFD15D"
			/>
			<path
				d="M16.5756 19.2461V20.3738H15.4245V19.2461C15.2174 19.0847 15.1017 18.8082 15.1017 18.5554C15.1017 18.0484 15.4934 17.6342 16.0001 17.6342C16.5064 17.6342 16.9206 18.0484 16.9206 18.5554C16.9205 18.8082 16.7821 19.0847 16.5756 19.2461Z"
				fill="#B85140"
			/>
			<path
				d="M16.7134 18.5554C16.7134 18.164 16.3913 17.8419 16.0001 17.8419C15.6088 17.8419 15.2863 18.164 15.2863 18.5554C15.2863 18.8083 15.4245 19.0161 15.6319 19.1539V20.1895H16.3913V19.1539C16.5756 19.0161 16.7134 18.8083 16.7134 18.5554Z"
				fill="#324A5E"
			/>
			<path
				d="M18.3482 18.624H13.6518V14.4803C13.6518 13.1683 14.7103 12.1085 16 12.1085C17.3119 12.1085 18.3481 13.1682 18.3481 14.4803V18.624H18.3482ZM14.7567 17.542H17.2655V14.4802C17.2655 13.7661 16.6905 13.214 16 13.214C15.3094 13.214 14.7567 13.7661 14.7567 14.4802V17.542Z"
				fill="#FFD15D"
			/>
			<path
				d="M12.3853 16.9435C12.3853 16.5747 12.6842 16.2758 13.053 16.2758H13.0762H18.946C19.3148 16.2758 19.6138 16.5747 19.6138 16.9435V21.0186C19.6138 21.4001 19.3046 21.7093 18.9232 21.7093H13.053C12.6842 21.7093 12.3853 21.4104 12.3853 21.0416V21.0186V16.9435ZM16.7135 18.5554C16.7135 18.164 16.3914 17.842 16.0002 17.842C15.6088 17.842 15.2864 18.164 15.2864 18.5554C15.2864 18.6818 15.3209 18.7969 15.3813 18.8977C15.4974 19.0913 15.632 19.2896 15.632 19.5154V19.8098C15.632 20.0195 15.802 20.1895 16.0117 20.1895C16.2214 20.1895 16.3914 20.0195 16.3914 19.8098V19.4995C16.3914 19.282 16.5129 19.0876 16.6209 18.8989C16.6788 18.7978 16.7135 18.6823 16.7135 18.5554Z"
				fill="#FC6F58"
			/>
			<path
				d="M16.5756 19.2461V20.3738H15.4245V19.2461C15.2174 19.0847 15.1017 18.8082 15.1017 18.5554C15.1017 18.0484 15.4934 17.6342 16.0001 17.6342C16.5064 17.6342 16.9206 18.0484 16.9206 18.5554C16.9205 18.8082 16.7821 19.0847 16.5756 19.2461Z"
				fill="#B85140"
			/>
			<path
				d="M16.7134 18.5554C16.7134 18.164 16.3913 17.8419 16.0001 17.8419C15.6088 17.8419 15.2863 18.164 15.2863 18.5554C15.2863 18.8083 15.4245 19.0161 15.6319 19.1539V20.1895H16.3913V19.1539C16.5756 19.0161 16.7134 18.8083 16.7134 18.5554Z"
				fill="#324A5E"
			/>
		</svg>
	);
};
